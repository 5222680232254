// eslint-disable-next-line
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby-link";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { getImageUrl } from "takeshape-routing";
import Link from "../components/link";
import LazyImage from "../components/lazyImage";
import StickyBlock from "../js/sticky";
import WorkBannerBottom from "../components/aboutpage/work-banner-bottom";

const WorkPage = ({ data }) => {
  const setting = data.takeshape.getSetting;
  let workList = data.takeshape.workList.items;
  const workPageData = data.takeshape.workPage;

  const [stateData, setStateValues] = useState({
    isGrid: true,
  });

  const titleRefs = [];

  const setTitleRef = (ref) => {
    titleRefs.push(ref);
  };

  const captionRefs = [];

  const setCaprionRef = (ref) => {
    captionRefs.push(ref);
  };

  const StickyBlocks = [];

  useEffect(() => {
    setTimeout(() => {
      for (let i in titleRefs) {
        let sticky = new StickyBlock({
          stickyElement: titleRefs[i],
          limitElement: captionRefs[i],
          minWidth: 1024,
        });
        StickyBlocks.push(sticky);
      }
    }, 200);

    return () => {
      StickyBlocks.forEach((sticky) => {
        if (
          sticky &&
          typeof sticky === "object" &&
          typeof sticky.destroy === "function"
        ) {
          sticky.destroy();
        }
      });
    };
  }, []);

  let baseClass = "layout-wrapper";
  let modClass = stateData.isGrid ? " grid-wrapper" : " list-wrapper";
  baseClass += modClass;

  if (
    (!workPageData.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
    (!workPageData.enableOnStaging &&
      process.env.GATSBY_ENVIRONMENT === "staging")
  ) {
    if (typeof window !== "undefined") {
      navigate("/");
    }
  }
  if (process.env.GATSBY_ENVIRONMENT === "staging") {
    workList = workList.filter((work) => work.enableOnStaging === true);
  } else {
    workList = workList.filter((work) => work.enabled === true);
  }
  return (
    <Layout headerClass="header--dark">
      <Seo
        title={workPageData.pageTitle}
        description={workPageData.pageDescription}
      />
      <div className="main--wrap">
        <div className={baseClass}>
          <div className="work-page-container container container--bordered container--fluid">
            <div className="layout-switcher box box--splitted box--bottom-bordered">
              <h1 className="indent-text complex-heading complex-heading--half">
                ({workList.length}) Featured Projects
                <span className="mode-wrapper">
                  View Work:
                  <span
                    onClick={(e) =>
                      setStateValues({ ...stateData, isGrid: false })
                    }
                    className={stateData.isGrid ? "" : "active"}
                  >
                    List
                  </span>{" "}
                  /
                  <span
                    onClick={(e) =>
                      setStateValues({ ...stateData, isGrid: true })
                    }
                    className={stateData.isGrid ? "active" : ""}
                  >
                    Grid
                  </span>
                </span>
              </h1>
            </div>
            <ul
              className="grid-list-layout listreset box box--splitted"
              itemScope
              itemType="http://schema.org/ItemList"
            >
              {workList.map((element, idx) => {
                return (
                  <li
                    className="grid-list-layout__item gs-animate box box--bottom-bordered"
                    key={idx}
                    itemProp="itemListElement"
                  >
                    <Link
                      to={element.link}
                      tabIndex="0"
                      className="img-link grid-list-layout__image-wrapper"
                      itemScope
                      itemType="http://schema.org/ImageObject"
                    >
                      <LazyImage
                        src={getImageUrl(element.thumbnail.path, {
                          fit: "crop",
                          auto: "compress",
                        })}
                      />
                    </Link>
                    <div
                      className="grid-list-layout__popup-wrapper"
                      itemScope
                      itemType="http://schema.org/ImageObject"
                    >
                      <Link to={element.link} tabIndex="0" className="img-link">
                        <img
                          src={getImageUrl(element.popThumb.path, {
                            fit: "crop",
                            auto: "compress",
                          })}
                          alt={element.popThumb.title || ""}
                          itemProp="contentUrl"
                        />
                      </Link>
                    </div>
                    <div className="grid-list-layout__text-wrapper flex-column flex-column--desktop-column">
                      <div className="grid-list-layout__text-wrapper__index">
                        <span className="position-from">
                          {idx + 1 + " / " + workList.length}
                        </span>
                        <span className="position">{idx + 1}</span>
                        <Link to={element.link} tabIndex="0" className="title">
                          {element.pageTitle}
                        </Link>
                      </div>
                      <div className="grid-list-layout__content-wrapper">
                        <h2
                          className="h1 complex-heading indent-text"
                          ref={setTitleRef}
                        >
                          <Link
                            to={element.link}
                            tabIndex="0"
                            className="title-link"
                          >
                            <sup>{element.pageTitle}</sup>
                            {element.summary}
                          </Link>
                        </h2>
                        <span ref={setCaprionRef} className="caption">
                          {element.disciplines
                            .map((disc) => disc.title)
                            .join(",")}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <WorkBannerBottom></WorkBannerBottom>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WorkPage;

export const query = graphql`
  query {
    takeshape {
      getSetting {
        seoContent
      }
      workPage: getWork {
        pageTitle
        pageDescription
        enabled
        enableOnStaging
      }
      workList: getWorkDetailList(
        size: 50
        sort: { field: "position", order: "asc" }
      ) {
        items {
          enableOnStaging
          enabled
          popThumb {
            path
            title
          }
          thumbnail {
            path
            title
          }
          summary
          disciplines {
            title
            link
          }
          position
          pageTitle
          link
          _id
        }
      }
    }
  }
`;
