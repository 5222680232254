import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const WorkBannerBottom = () => {
  const data = useStaticQuery(graphql`
    query {
      Image18: file(relativePath: { eq: "img18.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: CONSTRAINED, width: 2048)
        }
      }
    }
  `);

  return (
    <section className="section gs-animate title-section">
      <div className="container--fluid">
        <div className="brand-img__wrap">
          <div
            className="brand-img__image box box--bottom-bordered long-box padded"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <GatsbyImage
              image={data.Image18.childImageSharp.gatsbyImageData}
              alt="Image description"
              itemProp="contentUrl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkBannerBottom;
