const StickyBlock = function (data) {
  let {
    stickyElement,
    limitElement,
    mode = "to",
    offset = 0,
    maxWidth = -1,
    minWidth = -1,
  } = data;

  if (!stickyElement || !limitElement) {
    return false;
  }

  const getSpaceDistance = () => {
    if (mode === "to") {
      return (
        limitElement.getClientRects()[0].top -
        stickyElement.getClientRects()[0].bottom -
        offset
      );
    }
    return (
      limitElement.getClientRects()[0].bottom -
      stickyElement.getClientRects()[0].bottom -
      offset
    );
  };

  let spaceDistance = getSpaceDistance();
  let prevTopPosition = 0;

  const setSticky = () => {
    if (window.innerWidth > maxWidth && maxWidth > 0) {
      return false;
    }

    if (window.innerWidth < minWidth && minWidth > 0) {
      return false;
    }

    let stickyElementRects = stickyElement?.getClientRects()[0];
    let limitElementRects = limitElement?.getClientRects()[0];
    let top = stickyElementRects?.top;

    if (top < offset) {
      let newPadding = -top;

      if (mode === "to") {
        if (
          (prevTopPosition > top &&
            newPadding <= spaceDistance &&
            limitElementRects?.top >= stickyElementRects?.bottom) ||
          prevTopPosition < top
        ) {
          stickyElement.style.paddingTop = newPadding + "px";
          prevTopPosition = top;
        }
      } else {
        if (
          (prevTopPosition > top &&
            newPadding <= spaceDistance &&
            limitElementRects.bottom >= stickyElementRects.bottom) ||
          prevTopPosition < top
        ) {
          stickyElement.style.paddingTop = newPadding + "px";
          prevTopPosition = top;
        }
      }
    }

    if (top >= offset) {
      stickyElement.style.paddingTop = "0px";
    }
  };

  const onResize = () => {
    window.removeEventListener("scroll", setSticky);
    stickyElement.style.paddingTop = 0;
    prevTopPosition = 0;
    spaceDistance = getSpaceDistance();
    window.addEventListener("scroll", setSticky);
  };
  window.addEventListener("scroll", setSticky);
  window.addEventListener("resize", onResize);

  this.destroy = () => {
    window.removeEventListener("scroll", setSticky);
    window.removeEventListener("resize", onResize);
  };
};

export default StickyBlock;
